import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import '../../../css/favoritesButton/favorites-button.scss';
import App from './App';

const getAssetId = (containerElement: HTMLElement) => {
  return parseInt(containerElement.getAttribute('data-asset-id'), 10);
};

const getAssetType = (containerElement: HTMLElement) => {
  // Convert 'Asset' to 'assets', 'Bundle' to 'bundles' for endpoint handling
  let itemType = containerElement.getAttribute('data-item-type');
  itemType += 's';
  return itemType.toLocaleLowerCase();
};

ready(() => {
  const containerElement = document.getElementById('favorites-button');

  if (containerElement) {
    render(
      <App assetId={getAssetId(containerElement)} itemType={getAssetType(containerElement)} />,
      containerElement
    );
  }
});
