import { EnvironmentUrls } from 'Roblox';
import { urlService } from 'core-utilities';

export const LoginUrlService = {
  getLoginUrl: (): string => {
    const returnPath = encodeURIComponent(window.location.pathname + window.location.search);
    return urlService.getAbsoluteUrl(`/newlogin?returnurl=${returnPath}`);
  }
};

export default LoginUrlService;
